import { defineStore, acceptHMRUpdate } from 'pinia'

/**
 * This store contains app-wide variables
 */
export const useAppStore = defineStore('App', {
    state: () => ({
        // Navigation drawer state
        navDrawer: false,

        // Header links
        navbarItems: [
            { title: 'Home', icon: 'mdi-home-circle-outline', route: '/' },
            { title: 'Shop', icon: 'mdi-shopping-outline', route: '/shop' },
            { title: 'Deals', icon: 'mdi-sale', route: '/deals' },
            { title: 'Offers', icon: 'mdi-sale', route: '/offers' },
            { title: 'Services', icon: 'mdi-offer', route: '/services' },
            { title: 'Support', icon: 'mdi-help-circle-outline', route: '/support' },
        ],

        // Footer links
        footerLinks: [
            {
                title: 'Company',
                links: [
                    { title: 'About', route: '/about' },
                    // { title: 'Team', route: '/team' },
                    { title: 'Contact', route: '/support' },
                    { title: 'Terms of Use', route: '/terms' },
                    { title: 'Privacy Policy', route: '/privacy' },
                    { title: 'Cookie Policy', route: '/cookie' },
                ],
            },
            {
                title: 'Resources',
                links: [
                    { title: 'Support', route: '/support' },
                    { title: 'FAQs', route: '/faqs' },
                    //{ title: 'Unsubscribe', route: '/unsubscribe' },
                    // { title: 'Downloads', route: '/download' },
                ],
            },
            {
                title: 'Services',
                links: [
                    { title: 'Custom GUI', route: '/services/customgui' },
                    //{ title: 'Advertise with Us', route: '/partner' },
                    //{ title: 'Start Selling', route: '/seller' },
                    //{ title: '2nd Hand Market', route: '/market' },
                    { title: 'Exclusive Offers', route: '/offers' },
                    //{ title: 'Jobs', route: '/jobs' },
                    //{ title: 'News', route: '/news' },
                ],
            },
        ],

        // Social network links
        socNetworkLinks: [
            {
                title: 'Facebook',
                icon: 'mdi-facebook',
                route: 'https://facebook.com/satyatunes',
                hoverColor: '#4267B2',
            },
            { title: 'Twitter', icon: 'mdi-twitter', route: 'https://twitter.com/satyatunes', hoverColor: '#1DA1F2' },
            {
                title: 'Instagram',
                icon: 'mdi-instagram',
                route: 'https://instagram.com/satyatunes',
                hoverColor: '#E43BB4',
            },
            { title: 'YouTube', icon: 'mdi-youtube', route: 'https://youtube.com/satyatunes', hoverColor: '#F70000' },
            // { title: 'Pinterest', icon: 'mdi-pinterest', route: 'https://pinterest.com/satyatunes', hoverColor: '#E60023' },
        ],

        // Parallax images
        parallaxImages: [
            '/wbst-parallax-01.webp',
            '/wbst-parallax-02.webp',
            '/wbst-parallax-03.webp',
            '/wbst-parallax-04.webp',
        ],

        // sT early designs
        earlyDesigns: [
            {
                alt: 'Alchemy Player Skin',
                source: 'exod-almy-main-961x622.webp',
            },
            {
                alt: 'Wusik 4K Blue',
                source: 'exod-wusik-blue.webp',
            },
            {
                alt: 'Wusik 4K Retro',
                source: 'exod-wusik-retro.webp',
            },
            {
                alt: 'Bluesy for Poise',
                source: 'exod-poise-main-803x378.webp',
            },
            {
                alt: 'A light theme for Reaper',
                source: 'exod-reaper-light-1280x756.webp',
            },
            {
                alt: 'A dark theme for Reaper',
                source: 'exod-reaper-dark-1280x754.webp',
            },
            {
                alt: 'Black Beauty - A brushed dark metal skin for Stereo Chorus',
                source: 'exod-blkb-main-608x161.webp',
            },
            {
                alt: 'Metal Beauty - A brushed metal skin for Stereo Chorus',
                source: 'exod-mtlb-main-608x159.webp',
            },
        ],

        // sT portfolio site logos
        foliositeLogo: [
            {
                alt: 'ArtStation',
                source: 'logo-artstation-grey.webp',
                sourceRgb: 'logo-artstation-rgb.webp',
                url: 'https://www.artstation.com/satyatunes',
            },
            {
                alt: 'Behance',
                source: 'logo-behance-grey.webp',
                sourceRgb: 'logo-behance-rgb.webp',
                url: 'https://www.behance.net/satyatunes',
            },
            {
                alt: 'Deviant Art',
                source: 'logo-daviant-art-grey.webp',
                sourceRgb: 'logo-daviant-art-rgb.webp',
                url: 'https://www.deviantart.com/satyatunes',
            },

            {
                alt: 'Dribbble',
                source: 'logo-dribble-grey.webp',
                sourceRgb: 'logo-dribble-rgb.webp',
                url: 'https://dribbble.com/satyatunes',
            },
            {
                alt: 'Instagram',
                source: 'logo-instagram-grey.webp',
                sourceRgb: 'logo-instagram-rgb.webp',
                url: 'https://www.instagram.com/satyatunes',
            },
            {
                alt: 'Pinterest',
                source: 'logo-pinterest-grey.webp',
                sourceRgb: 'logo-pinterest-rgb.webp',
                url: 'https://www.pinterest.com/satyatunes',
            },
        ],

        // sT 3D shots
        st3DShots: [
            {
                alt: 'Cheeze Machine Pro Side View',
                source: 'ex3d-cmpro.webp',
            },
            {
                alt: 'Cheeze Machine Pro Knob',
                source: 'ex3d-cmpro-knob.webp',
            },
            {
                alt: 'ButterSynth Top View',
                source: 'ex3d-butter-synth.webp',
            },
            {
                alt: 'Vengeance Avenger Legacy Top View',
                source: 'ex3d-avenger-legacy.webp',
            },
            {
                alt: 'Cheeze Machine Pro Top View',
                source: 'ex3d-cmpro-top.webp',
            },
            {
                alt: 'Glacier White Top View',
                source: 'ex3d-glacier-white.webp',
            },
        ],

        // sT latest designs
        latestDesigns: [
            {
                alt: 'UI/UX designs for 2getherAudio',
                source: 'exui-2getheraudio-ui.webp',
                cols: 4,
            },
            {
                alt: 'Our own Glacier White skin for DUNE 3',
                source: 'exui-glacier-white.webp',
                cols: 5,
            },
            {
                alt: 'UI designs for Avenger',
                source: 'exui-vengeance-sound-ui.webp',
                cols: 3,
            },
            {
                alt: 'Prism Kontakt GUI',
                source: 'exkn-prism-banner.webp',
                cols: 3,
            },
            {
                alt: 'UI/UX design for ButterSynth iPad App',
                source: 'exui-butter-synth.webp',
                cols: 6,
            },
            {
                alt: 'UI/UX designs for KV331 Audio',
                source: 'exui-kv331-audio-ui.webp',
                cols: 3,
            },
            {
                alt: 'UI design for ArcSyn',
                source: 'exui-arcsyn.webp',
                cols: 4,
            },
            {
                alt: 'Our own Harbor Blue skin for DUNE 2',
                source: 'exui-harbor-blue.webp',
                cols: 4,
            },
            {
                alt: 'Lunaris Kontakt GUI',
                source: 'exkn-lunaris.webp',
                cols: 4,
            },
        ],

        // sT kontakt designs
        kontaktDesigns: [
            {
                alt: 'Cyborg 2',
                source: 'exkn-cyborg2.webp',
                cols: 2,
            },
            {
                alt: 'Ava Music Group Prism',
                source: 'exkn-prism.webp',
                cols: 5,
            },
            {
                alt: 'Dual 2',
                source: 'exkn-dual2.webp',
                cols: 2,
            },
            {
                alt: 'Foleysoft Footsteps',
                source: 'exkn-footsteps.webp',
                cols: 3,
            },
            {
                alt: 'Dual 3',
                source: 'exkn-dual3.webp',
                cols: 2,
            },
            {
                alt: 'Dual',
                source: 'exkn-dual.webp',
                cols: 3,
            },
            {
                alt: 'Luftrum Lunaris',
                source: 'exkn-lunaris-ui.webp',
                cols: 5,
            },
            {
                alt: 'Piano',
                source: 'exkn-piano.webp',
                cols: 2,
            },
            {
                alt: 'Xtant Audio Elkatwin',
                source: 'exkn-elkatwin.webp',
                cols: 2,
            },
            {
                alt: 'SoundFxWizard TextRobot',
                source: 'exkn-textrobot.webp',
                cols: 4,
            },
            {
                alt: 'Xtant Audio Uilleann Pipes',
                source: 'exkn-uilleannpipes.webp',
                cols: 4,
            },
            {
                alt: 'Trailer Hits Vol. 1',
                source: 'exkn-trailerhits.webp',
                cols: 2,
            },
        ],

        // sT UI designs showcase
        uiDesigns: [
            {
                alt: 'We did a facelift for ArcSyn',
                source: 'exui-arcsyn.webp',
                cols: 2,
            },
            {
                alt: 'We crafted several UIs for 2getherAudio',
                source: 'exui-2getheraudio-ui.webp',
                cols: 2,
            },
            {
                alt: 'Vintage emulation UI for Cheeze Machine Pro',
                source: 'exui-cm-pro.webp',
                cols: 2,
            },
            {
                alt: 'Hardware emulation UI for Rich and Rich Drums',
                source: 'exui-rich-drums.webp',
                cols: 2,
            },
            {
                alt: 'Modern designs for Clap Maker, Kick Synth and Snare Drum',
                source: 'exui-kick-snare-clap.webp',
                cols: 2,
            },
            {
                alt: 'Another vintage emulation for Ticky Clav',
                source: 'exui-ticky-clav.webp',
                cols: 2,
            },
            {
                alt: 'Sci-fi theme for Space Duck',
                source: 'exui-space-duck.webp',
                cols: 2,
            },
            {
                alt: 'Modern design for St3reo',
                source: 'exui-stereo.webp',
                cols: 2,
            },
            /*{
                alt: 'Hardware look-a-like UI for Vanylla',
                source: 'exui-vinylla.webp',
                cols: 2,
            },*/
            {
                alt: 'Sky Light theme for Nora 2 arpeggiator',
                source: 'exui-skylight.webp',
                cols: 2,
            },
            {
                alt: 'Glacier White for DUNE 3',
                source: 'exui-glacier-white.webp',
                cols: 2,
            },
            {
                alt: 'Harbor Blue - A DUNE 2 skin. It was very popular during its time.',
                source: 'exui-harbor-blue.webp',
                cols: 2,
            },
            {
                alt: 'ButterSynth iPad Synth UI/UX Design',
                source: 'exui-butter-synth.webp',
                cols: 2,
            },
            {
                alt: 'We crafted several UIs for Vengeance-Sound Avenger',
                source: 'exui-vengeance-sound-ui.webp',
                cols: 2,
            },
            {
                alt: 'Vintage style theme for Avenger',
                source: 'exui-avenger-vintage.webp',
                cols: 2,
            },
            {
                alt: 'Chrono-Jade theme for Avenger',
                source: 'exui-avenger-chrono-jade.webp',
                cols: 2,
            },
            {
                alt: 'We crafted several UIs for SynthMaster 2, SynthMaster One and SynthMaster Player',
                source: 'exui-kv331-audio-ui.webp',
                cols: 2,
            },
            {
                alt: 'Burak Yeter Edition for SynthMaster 2',
                source: 'exui-sm2-burak-yeter-edition.webp',
                cols: 2,
            },
            {
                alt: 'Default skin for SynthMaster One',
                source: 'exui-sm1-blue.webp',
                cols: 2,
            },
            {
                alt: 'A dark skin for SynthMaster 2',
                source: 'exui-sm2-dark.webp',
                cols: 2,
            },
            {
                alt: 'A light skin for SynthMaster One',
                source: 'exui-sm1-white.webp',
                cols: 2,
            },
            {
                alt: 'Default skin for SynthMaster Player',
                source: 'exui-sm-player.webp',
                cols: 2,
            },
            {
                alt: 'A custom skin for DUNE 2',
                source: 'exui-bjulin-magnitude.webp',
                cols: 2,
            },
            {
                alt: 'A custom skin for Spire',
                source: 'exui-polar.webp',
                cols: 2,
            },
            /*{
                alt: 'UI designs for Wusik 4000',
                source: 'exui-wusik-ui.webp',
                cols: 2,
            },*/
            {
                alt: 'A custom Hive skin emulating Retro gaming device',
                source: 'exui-bjulin-retro-hive.webp',
                cols: 2,
            },
        ],

        // sT Cover Arts showcase
        coverArts: [
            {
                alt: "80's Synth - Volume I",
                source: 'excv-80s-synths-vol1.webp',
                cols: 3,
            },
            {
                alt: 'Dark Retro Synths',
                source: 'excv-dark-retro-synths.webp',
                cols: 3,
            },
            {
                alt: 'Deep Dubstep & Dark Garage',
                source: 'excv-deep-dubstep-dark-garage.webp',
                cols: 3,
            },
            {
                alt: 'Dubstep Throne',
                source: 'excv-dubstep-throne.webp',
                cols: 3,
            },
            {
                alt: 'Fear and Horror 2',
                source: 'excv-fear-and-horror2.webp',
                cols: 3,
            },
            {
                alt: 'Future Bass',
                source: 'excv-future-bass.webp',
                cols: 3,
            },
            {
                alt: 'Future RnB',
                source: 'excv-future-rnb.webp',
                cols: 3,
            },
            {
                alt: 'Kawaii Future Bass',
                source: 'excv-kowaii-future-bass.webp',
                cols: 3,
            },
            {
                alt: 'OVO',
                source: 'excv-ovo.webp',
                cols: 3,
            },
            {
                alt: 'Pop-Fusion Synths',
                source: 'excv-pop-fusion synths.webp',
                cols: 3,
            },
            {
                alt: 'Pure 80s',
                source: 'excv-pure-80s.webp',
                cols: 3,
            },
            {
                alt: 'Retromania',
                source: 'excv-retromania.webp',
                cols: 3,
            },
            {
                alt: 'Synthwave Volume 2',
                source: 'excv-synthwave-vol2.webp',
                cols: 3,
            },
            {
                alt: 'Totally Rad Retrowave',
                source: 'excv-totally-rad retrowave.webp',
                cols: 3,
            },
            {
                alt: 'Uber Brutal Dubstep',
                source: 'excv-uber-brutal-dubstep.webp',
                cols: 3,
            },
            {
                alt: 'Memoirs of a World Traveller',
                source: 'excv-memoirs.webp',
                cols: 3,
            },
        ],

        // Testimonials
        testimonials: [
            {
                person: 'Arto Vaarala',
                position: 'Kirnu Interactive',
                product: 'ButterSynth',
                source: 'logo-arto-vaarala-grey.webp',
                content:
                    'I had the pleasure of collaborating with Satya on my ButterSynth project, and I can confidently say that their contribution was nothing short of exceptional. From the initial concept discussions to the final implementation, Satya showcased an unparalleled level of creativity, professionalism, and attention to detail.',
            },
            {
                person: 'Steven Cook',
                position: 'SPC Plugins',
                product: 'ArcSyn',
                source: 'logo-spc-plugins-grey.webp',
                content:
                    "Satya has been a pleasure to work with. That his design work is of an extremely high standard goes without saying, but perhaps of equal importance to me, his communication skills and professionalism are also second to none. I had misgivings about working with a designer having had some less than ideal experiences previously, but I need not have worried as Satya's people skills are every bit as good as his design skills.",
            },
            {
                person: 'Juan Juez',
                position: 'SquaredHeads',
                product: 'Nora 2',
                source: 'logo-squaredhead-grey.webp',
                content:
                    'Working with Satya has been a pleasure. I only had to give him a few vague concepts about what I wanted, then he did it and improved the design with his own ideas. He was pretty flexible, asking for feedback and then adjusting its work until I was absolutely satisfied.',
            },
            {
                person: 'Clément Ducasse',
                position: 'Ava Music Group',
                product: 'Prism',
                source: 'logo-ava-music-group-grey.webp',
                content:
                    'Satya is very effective in his work and can deliver in a short period of time. He is also very understanding and suggested us better design options to improve our products.',
            },
            {
                person: 'John Sterling',
                position: 'Sterling Angel',
                product: 'Spire',
                source: 'logo-sterling-angel-grey.webp',
                content:
                    "I recently hired Satya to modify the skin of a certain synth plugin (Reveal Sound Spire). He did an amazing job and was an absolute pleasure to work with. Fast, efficient, and really helped me fine-tune everything until I was satisfied. It wasn't hard though, as he was on top of things from the beginning. Thanks so much, Satya! I am inspired even more when working on my music!",
            },
            {
                person: 'Peter Richardson',
                position: 'Dance MIDI Samples',
                product: 'Ascension',
                source: 'logo-dance-midi-samples-grey.webp',
                content:
                    "Satya was excellent to work with and provided graphics to our exacting specifications. He was happy to make suggestions and changes to our design, which went through several drafts before completion. Satya is professional with great communication skills too - we'd recommend him to anyone looking for a GUI designer.",
            },
            {
                person: 'Luftrum',
                position: 'Luftrum Sound Design',
                product: 'Lunaris',
                source: 'logo-luftrum-grey.webp',
                content:
                    'I just knew Satya was the right designer for a new additional Lunaris GUI and I was right. Quality work, fast delivery, great communication and a high sense for details and ability to understand customer needs. He is such a pleasure to work with, I would hire him again any time of the day...',
            },
            {
                person: 'Stephen Wright',
                position: 'Swan Audio',
                product: 'Custom UIs for Diva',
                source: 'logo-swan-audio-grey.webp',
                content:
                    "Satya enables people like me to realise their vision with his design work. He is patient enough to really nail the job ensuring I've been delighted each time. It really counts and is what keeps me coming back to work with him.",
            },
            {
                person: 'W Mays',
                position: 'Kontakt Developer',
                product: 'Foleysoft FootSteps',
                source: 'logo-wm-grey.webp',
                content:
                    "Satya was able to make my ugly mockups into amazing GUIs and deployed all the required asset deliverables for Kontakt Libraries. It's hard to find graphic designers that know all the intricacies of Kontakt and Audio apps so im glad I found Satya who also was a pleasure to work with.",
            },
            {
                person: 'David Healy',
                position: 'Xtant Audio',
                product: 'Uilleann Pipes, Elkatwin',
                source: 'logo-xtant-audio-grey.webp',
                content:
                    'Clear and functional designs combined with a thoughtful attention to detail made working with Satya a great experience.',
            },
        ],

        // sT sound design clients
        sdClients: [
            {
                alt: 'Vengeance Sound',
                source: 'logo-vengeance-grey.webp',
            },
            {
                alt: 'Tone 2',
                source: 'logo-tone2-grey.webp',
            },
            {
                alt: 'Synapse Audio',
                source: 'logo-synapse-audio-grey.webp',
            },
            {
                alt: 'Rob Papen',
                source: 'logo-robpapen-grey.webp',
            },
            {
                alt: 'Melda',
                source: 'logo-melda-grey.webp',
            },
            {
                alt: 'Linplug',
                source: 'logo-linplug-grey.webp',
            },
        ],

        // sT clients
        stClients: [
            {
                alt: 'Arto Vaarala',
                source: 'logo-arto-vaarala-grey.webp',
            },
            {
                alt: 'Vengeance-Sound',
                source: 'logo-vengeance-sound-grey.webp',
            },
            {
                alt: 'KV331 Audio',
                source: 'logo-kv331-audio-grey.webp',
            },
            {
                alt: 'Lennar Digital',
                source: 'logo-lennar-digital-grey.webp',
            },
            {
                alt: 'Luftrum',
                source: 'logo-luftrum-grey.webp',
            },
            {
                alt: 'Melda Production',
                source: 'logo-melda-grey.webp',
            },
            {
                alt: '2gether Audio',
                source: 'logo-2gether-audio-grey.webp',
            },
            {
                alt: 'SPC Plugins',
                source: 'logo-spc-plugins-grey.webp',
            },
            {
                alt: 'Swan Audio',
                source: 'logo-swan-audio-grey.webp',
            },
            {
                alt: 'Keilwerth Audio',
                source: 'logo-keilwerth-audio-grey.webp',
            },
            {
                alt: 'Ava Music Group',
                source: 'logo-ava-music-group-grey.webp',
            },
            {
                alt: 'Dance MIDI Samples',
                source: 'logo-dance-midi-samples-grey.webp',
            },
            {
                alt: 'Bjulin',
                source: 'logo-bjulin-grey.webp',
            },
            {
                alt: 'Xenos Soundworks',
                source: 'logo-xenos-soundworks-grey.webp',
            },
            {
                alt: 'Xtant-Audio',
                source: 'logo-xtant-audio-grey.webp',
            },
            {
                alt: 'Wusik',
                source: 'logo-wusik-grey.webp',
            },
            {
                alt: 'W Mays',
                source: 'logo-wm-grey.webp',
            },
            {
                alt: 'SquaredHead',
                source: 'logo-squaredhead-grey.webp',
            },
        ],

        // Services page chips
        servicesChips: [
            {
                text: 'UI/UX',
                section: 'ui',
            },
            {
                text: 'Kontakt UI',
                section: 'kontakt',
            },
            {
                text: '3D',
                section: '3d',
            },
            {
                text: 'Custom GUI',
                section: 'customui',
            },
            // {
            //     text: 'Cover Arts',
            //     section: 'coverarts',
            // },
            {
                text: 'Sound Design',
                section: 'soundesign',
            },
            {
                text: 'Web Design',
                section: 'webdesign',
            },
            {
                text: 'Clients',
                section: 'theclients',
            },
            {
                text: 'Testimonials',
                section: 'thetestimonials',
            },
        ],

        // Offers page chips
        offersChips: [
            {
                text: '3% off on Spire',
                section: 'SP3CPN',
            },
            {
                text: '50% off on Spire for Indian Buyers',
                section: 'SP50CN',
            },
            {
                text: '10% off on SynthMaster',
                section: 'SM10CN',
            },
        ],

        // Placeholder images
        //noProductImage: 'nopd-1200x675-0.webp',
        noProfileImage: 'prof-none.webp',
        noProfileCover: 'prof-none-cover.webp',
        noSidebarDealAds: {
            imgSources: [
                { id: '1', media: '(max-width:960px)', img: 'wbst-sidebar-deals-blank-xs.webp' },
                { id: '2', media: '(min-width:960px)', img: 'wbst-sidebar-deals-blank-md.webp' },
            ],
        },

        // Products sort options on shop page
        productsSortOptions: [
            'Name: A to Z',
            'Name: Z to A',
            'Price: Low to High',
            'Price: High to Low',
            //'Rating: Low to High',
            //'Rating: High to Low',
        ],

        // Deals sort options on deals page
        dealsSortOptions: [
            'Brand: A to Z',
            'Brand: Z to A',
            'Price: Low to High',
            'Price: High to Low',
            'DaysLeft: Low to High',
            'DaysLeft: High to Low',
        ],

        // Order cancellation reasons
        cancelReasons: [
            {
                label: 'Selected the wrong product.',
                value: 1,
            },
            {
                label: 'Cancelled by mistake, will reorder.',
                value: 2,
            },
            {
                label: 'Changed my mind, I no longer need the item.',
                value: 3,
            },
            {
                label: 'Issue with my PayPal account.',
                value: 4,
            },
            {
                label: 'Other (fill in reason below)',
                value: 5,
            },
        ],

        // Newsletter unsubscribe reasons
        unsubscribeReasons: [
            {
                label: 'I never signed up',
                value: 1,
            },
            {
                label: 'I no longer want to receive these emails',
                value: 2,
            },
            {
                label: 'The content is not relevant to me',
                value: 3,
            },
            {
                label: 'I get too many emails from you',
                value: 4,
            },
            {
                label: 'Other (fill in reason below)',
                value: 5,
            },
        ],

        // The company address
        companyAddressIndia: {
            name: 'satYatunes',
            addressLine1: '1104 Tower 6 Lotus Panache ',
            addressLine2: 'Sector 110',
            city: 'Noida',
            state: 'Uttar Pradesh',
            zipcode: '201304',
            country: 'India',
            tel: '',
            fax: '',
            email: 'satyabc@yahoo.com',
        },

        lastUpdatedDates: {
            cookiePolicy: '06 Mar 2025',
            privacyPolicy: '06 Mar 2025',
            termsOfUse: '06 Mar 2025',
        },

        // Frequently asked questions
        faqs: [
            {
                title: 'What format will the downloads be in?',
                content:
                    'This will vary depending on the product, but we will clearly state the format ' +
                    '(e.g., Zip, etc. ) on each product page.',
            },
            {
                title: 'How will I receive my downloads?',
                content:
                    'Once your purchase is complete, you will receive an email with a download link. ' +
                    'In case you are unable to download the product or you do not receive an email from ' +
                    'us then contact us and we will be happy to help you.',
            },
            {
                title: 'How many times can I download my purchase?',
                content:
                    'At this time we do not have any limit but this will vary depending on the ' +
                    ' product. If there is a limit then you will see it in the email with download links. ' +
                    'Generally, you will have at least a few download attempts.',
            },
            {
                title: 'I did not receive any e-mail with download links.',
                content:
                    'We do our best to send emails quickly, but sometimes they get delayed. Here ' +
                    'is how it works: <br/><br/><ul class="ml-8"><li> Free orders: We send the email to the address you used ' +
                    'at checkout.</li> <li> Paid orders: We send it to your PayPal email.</li></ul><br/> ' +
                    " If you haven't gotten it yet, check your spam folder before letting us know.",
            },
            {
                title: 'I have lost the download links. Can you resend?',
                content:
                    'No problem getting you those download links again! Just to make sure we send the' +
                    ' right ones, please send us the email address you used for your PayPal ' +
                    'purchase or the transaction number itself (you can find that in your PayPal account).',
            },
            {
                title: 'Do you offer refunds?',
                content:
                    "All sales are final. We cannot offer refunds, so please make sure it's what you want " +
                    'before you buy. We are happy to answer any questions you have first!',
            },
            {
                title: 'Can I modify the downloaded skin to create my own version?',
                content:
                    'You are allowed to modify the skin for your personal use only. ' +
                    'You must not share or sell the modified version.',
            },
        ],
    }),
    getters: {
        // Returns a random image
        parallaxImage: (state) => state.parallaxImages[(Math.random() * state.parallaxImages.length) | 0],
    },
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot))
}
